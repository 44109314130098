<template>
  <div style="padding: 4px">
    <iq-check-box />
  </div>
</template>

<script>
import IqCheckBox from '@/views/manager/components/mobile/components/modal/IqCheckBox'

const cityOptions = ['Shanghai', 'Beijing', 'Guangzhou', 'Shenzhen']

export default {
  components: { IqCheckBox },

  data() {
    return {
      checkAll: false,
      checkedCities: ['Shanghai', 'Beijing'],
      cities: cityOptions,
      isIndeterminate: true,
      ind: false,
    }
  },
  methods: {
    set_ind() {
      this.ind = true
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length

      this.checkAll = checkedCount === this.cities.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length
    },
  },
}
</script>

<style lang="sass">
.checkbox-own
  .el-checkbox__inner
    width: 20px
    height: 20px
    border-radius: 4px
    .el-checkbox__input.is-indeterminate .el-checkbox__inner,
    .el-checkbox__input.is-checked .el-checkbox__inner
      background-color: #4CA7D8
      border-color: #4CA7D8
    .el-checkbox__input.is-indeterminate .el-checkbox__inner::before,
    .el-checkbox__input.is-checked .el-checkbox__inner::before,
    .el-checkbox__inner::after
      top: 60%
      left: 60%
      transform: translate(-50%, -50%) scale(.5)
    .el-checkbox__input.is-checked .el-checkbox__inner::after
      transform: translate(-50%, -65%) rotate(45deg) scaleY(1.3)
</style>
<!--<template>-->
<!--  <div>-->
<!--    <div-->
<!--      style="-->
<!--        display: flex;-->
<!--        align-items: center;-->
<!--        justify-content: space-between;-->
<!--        width: 100%;-->
<!--      "-->
<!--    >-->
<!--      <el-input placeholder="Поиск">-->
<!--        <el-button-->
<!--          slot="append"-->
<!--          icon="el-icon-search"-->
<!--          style="-->
<!--            width: 20px;-->
<!--            display: flex;-->
<!--            align-items: center;-->
<!--            justify-content: center;-->
<!--          "-->
<!--        />-->
<!--      </el-input>-->
<!--    </div>-->
<!--    <el-checkbox-->
<!--      v-model="checkAll"-->
<!--      size="medium"-->
<!--      :indeterminate="isIndeterminate"-->
<!--      @change="handleCheckAllChange"-->
<!--    >-->
<!--      Выбрать все-->
<!--    </el-checkbox>-->
<!--    <div style="margin: 15px 0" />-->
<!--    <el-checkbox-group-->
<!--      v-model="checkedCities"-->
<!--      style="display: flex; flex-direction: column"-->
<!--      @change="handleCheckedCitiesChange"-->
<!--    >-->
<!--      <el-checkbox-->
<!--        v-for="city in cities"-->
<!--        :key="city"-->
<!--        :label="city"-->
<!--        style="margin-bottom: 20px"-->
<!--        class="checkbox-own"-->
<!--        size="medium"-->
<!--      >-->
<!--        {{ city }}-->
<!--      </el-checkbox>-->
<!--    </el-checkbox-group>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--const cityOptions = ['Shanghai', 'Beijing', 'Guangzhou', 'Shenzhen']-->

<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      checkAll: false,-->
<!--      checkedCities: ['Shanghai', 'Beijing'],-->
<!--      cities: cityOptions,-->
<!--      isIndeterminate: true,-->
<!--    }-->
<!--  },-->
<!--  methods: {-->
<!--    handleCheckAllChange(val) {-->
<!--      this.checkedCities = val ? cityOptions : []-->
<!--      this.isIndeterminate = false-->
<!--    },-->
<!--    handleCheckedCitiesChange(value) {-->
<!--      let checkedCount = value.length-->

<!--      this.checkAll = checkedCount === this.cities.length-->
<!--      this.isIndeterminate =-->
<!--        checkedCount > 0 && checkedCount < this.cities.length-->
<!--    },-->
<!--  },-->
<!--}-->
<!--</script>-->

<!--<style lang="sass">-->
<!--.checkbox-own-->
<!--  .el-checkbox__inner-->
<!--    width: 20px-->
<!--    height: 20px-->
<!--    border-radius: 4px-->
<!--    .el-checkbox__input.is-indeterminate .el-checkbox__inner,-->
<!--    .el-checkbox__input.is-checked .el-checkbox__inner-->
<!--      background-color: #4CA7D8-->
<!--      border-color: #4CA7D8-->
<!--    .el-checkbox__input.is-indeterminate .el-checkbox__inner::before,-->
<!--    .el-checkbox__input.is-checked .el-checkbox__inner::before,-->
<!--    .el-checkbox__inner::after-->
<!--      top: 60%-->
<!--      left: 60%-->
<!--      transform: translate(-50%, -50%) scale(.5)-->
<!--    .el-checkbox__input.is-checked .el-checkbox__inner::after-->
<!--      transform: translate(-50%, -65%) rotate(45deg) scaleY(1.3)-->
<!--</style>-->
